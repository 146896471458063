<script>
import { Line } from "vue-chartjs";
import { mapGetters } from "vuex";

export default {
  extends: Line,
  props: {
    label: {
      type: String,
    },
  },
  data() {
    return {
      WeightDataImport: [],
      WeightDataFormatted: [],
      options: {},
    };
  },
  created() {
    this.WeightDataImport = this.WeightDataChartStored;
    this.options = this.chartOptionsStored;
  },
  computed: {
    ...mapGetters(["WeightDataChartStored", "chartOptionsStored"]),
  },
  methods: {
    drawChart: function () {
      this.WeightDataImport = this.WeightDataChartStored;
      var weights = this.WeightDataImport.map((Weights) => Weights.weight);
      var dates = this.WeightDataImport.map((Dates) => Dates.date);
      this.WeightDataFormatted = [];

      for (var i = 0; i < dates.length; i++) {
        this.WeightDataFormatted.push(this.formatDate(dates[i]));
      }

      this.renderChart(
        {
          labels: this.WeightDataFormatted,
          datasets: [
            {
              label: this.label,
              data: weights,

              showLine: true,
              borderColor: "rgb(96,159,255)",
              steppedLine: false,

              fill: true,
              backgroundColor: "rgba(96,159,255,0.80)",
              hoverBackgroundColor: "",
              borderWidth: 5,

              pointStyle: "circle",
              pointRadius: 3,
              pointHoverRadius: 20,
              pointBackgroundColor: "rgb(61,138,255)",
              pointHoverBackgroundColor: "rgb(0,101,255)",

              pointBorderColor: "rgba(255,255,255,0.0)",
              pointHoverBorderColor: "rgba(255,255,255,0.0)",
              pointBorderWidth: 0,
              pointHoverBorderWidth: 0,
            },
          ],
        },
        this.options
      );
    },
    formatDate: function (strDate) {
      var calcMonth = Number(strDate.slice(3, 5));
      var monthAddZero = "";
      var calcDays = Number(strDate.slice(0, 2));
      var daysAddZero = "";

      if (calcMonth < 10) {
        monthAddZero = "0";
      }

      if (calcDays < 10) {
        daysAddZero = "0";
      }

      var newDateFormat =
        daysAddZero +
        calcDays.toString() +
        "." +
        monthAddZero +
        calcMonth.toString() +
        ".";

      monthAddZero = "";
      daysAddZero = "";

      return newDateFormat;
    },
  },
};
</script>

<style lang="scss">
</style>
