<template>
  <div id="Home">
    <main id="main">
      <div class="allContent">
        <div class="box headerH1">
          <h1>Waagi</h1>
        </div>
        <app-mainChart class="box mainChart"></app-mainChart>
        <app-scraper class="box mainScraper"></app-scraper>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["User"]),
  },
  created() {
    if (this.User.name == "" || this.User.name == null) {
      this.$router.push("/welcome");
    }
  },
};
</script>

<style lang="scss">
@import "src/style.scss";
</style>