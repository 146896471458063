import Vue from "vue";
import Vuex from "vuex";
import foodDB from "./data/foodDB.json";
import firebase from "./firebase";
import router from "./router";
import "@codetrix-studio/capacitor-google-auth";

import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';


Vue.use(Vuex);

let UserJS = localStorage.getItem("User");
let WeightDataJS = localStorage.getItem("WeightData");

export const store = new Vuex.Store({
    state: {
        User: UserJS ? JSON.parse(UserJS) : { name: '', mail: '', passW: '' },
        WeightDataStored: WeightDataJS ? JSON.parse(WeightDataJS) : [
            { weight: "0", date: "01.01.2020" },],
        WeightDataChartStored: [],
        chartOptionsStored: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: 'Gewicht über Zeit',
                fontColor: "rgb(255,255,255)",
            },
            legend: {
                display: false,
                labels: {
                    fontColor: 'rgb(255, 255, 255)'
                }
            },
            scales: {},
            animation: {
                duration: 0,
            },
        },
        foodDB: foodDB,
        userID: "",
        isLogedIn: false,
        showNavMenu: true,
        isLoading: false,
        isRegistering: false,
        gLogin: false,
        refresh: false,
        pulledForRefresh: false,
        refreshInterval: false,
    },
    getters: {
        User(state) {
            return state.User;
        },
        WeightDataStored(state) {
            return state.WeightDataStored;
        },
        WeightDataChartStored(state) {
            return state.WeightDataChartStored;
        },
        chartOptionsStored(state) {
            return state.chartOptionsStored;
        },
        foodDB(state) {
            return state.foodDB;
        },
        showNavMenu(state) {
            return state.showNavMenu;
        },
        isLoading(state) {
            return state.isLoading;
        },
        isLogedIn(state) {
            return state.isLogedIn;
        },
        isRegistering(state) {
            return state.isRegistering;
        },
        refresh(state) {
            return state.refresh;
        },
        pulledForRefresh(state) {
            return state.pulledForRefresh;
        },
        refreshInterval(state) {
            return state.refreshInterval;
        },
    },
    mutations: {
        updateUserData(state) {
            localStorage.setItem("User", JSON.stringify(state.User));
        },
        updateWeightData(state) {
            localStorage.setItem("WeightData", JSON.stringify(state.WeightDataStored));
        },
        updateWeightDataStored(state, newData) {
            state.WeightDataStored = newData;
            this.commit("updateWeightData");
        },
        updateWeightDataChartStored(state, newData) {
            state.WeightDataChartStored = newData;
        },
        updateShowNavMenu(state, isTrue) {
            state.showNavMenu = isTrue;
        },
        refresh(state) {
            state.refresh = true;
        },
        resetPulledForRefresh(state) {
            state.pulledForRefresh = false;
        },
        refreshInterval(state, boolean) {
            state.refreshInterval = boolean;
        },
    },
    actions: {
        async isUserLogedIn(context) {
            await firebase.auth().onAuthStateChanged(async function (user) {
                if (user && !context.state.isRegistering) {
                    context.state.isLogedIn = true;
                    context.state.userID = await firebase.auth().currentUser.uid;
                    context.state.User.name = await firebase.auth().currentUser.displayName;
                    context.state.User.mail = await firebase.auth().currentUser.email;
                } else {
                    context.state.isLogedIn = false;
                }
            });
        },
        async registerUser(context, userData) {
            if (context.state.isLogedIn == true) {
                alert("Sie sind noch eingeloggt");
            }
            else {
                context.state.isRegistering = true;
                context.state.isLoading = true;
                context.state.User.name = userData.name;
                context.state.User.mail = userData.mail;
                context.state.User.passW = userData.passW;
                await firebase.auth().createUserWithEmailAndPassword(context.state.User.mail, context.state.User.passW).then(async () => {
                    await firebase.auth().currentUser.updateProfile({ displayName: context.state.User.name }).then(async () => {
                        context.state.userID = await firebase.auth().currentUser.uid;
                        await this.dispatch("pushDatabaseData");
                        await this.dispatch("loginUser", userData);
                    }).catch((err) => {
                        alert(err);
                    });
                }).catch(async (err) => {
                    if (context.state.gLogin == false) {
                        alert(err);
                        return;
                    }
                    else {
                        console.log("Userkonto besteht bereits - Starte Anmeldeversuch");
                        await this.dispatch("loginUser", userData).catch((err) => {
                            alert(err);
                        });
                    }
                });
            }
        },
        async loginUser(context, userData) {
            if (context.state.isLogedIn == true && context.state.isRegistering == false) {
                alert("Sie sind noch eingeloggt");
            }
            else {
                // if (context.state.gLogin == true) {
                //     Plugins.GoogleAuth.signOut().catch((err) => {
                //         alert(err);
                //     });
                // }
                context.state.gLogin = false;
                context.state.isRegistering = false;
                context.state.isLoading = true;
                context.state.isLogedIn = true;
                context.state.User.mail = userData.mail;
                context.state.User.passW = userData.passW;
                await firebase.auth().signInWithEmailAndPassword(context.state.User.mail, context.state.User.passW).then(async () => {
                    context.state.userID = await firebase.auth().currentUser.uid;
                    context.state.User.name = await firebase.auth().currentUser.displayName;
                    await this.dispatch("pullDatabaseData");
                    await context.commit("updateUserData");
                    await context.commit("updateWeightData");
                    if (context.state.User.name == "" || context.state.User.name == null) {
                        router.push("/welcome");
                        this.commit("updateShowNavMenu", false);
                    }
                    else {
                        router.push("/");
                    }
                }).catch((err) => {
                    console.log(err);
                    alert("Benutzername oder Passwort falsch!")
                    context.state.isLogedIn = false;
                    context.state.isLoading = false;
                });
            }
        },
        async loginUserWithGoogle(context) {
            if (context.state.isLogedIn == true) {
                alert("Sie sind noch eingeloggt");
            }
            else {
                context.state.isLoading = true;
                if (Capacitor.platform == "web") {
                    console.log("Login through Web");
                    const provider = new firebase.auth.GoogleAuthProvider();
                    provider.addScope('profile');
                    provider.addScope('email');

                    await firebase.auth().signInWithPopup(provider);
                    context.state.userID = await firebase.auth().currentUser.uid;
                    context.state.User.name = await firebase.auth().currentUser.displayName;
                    context.state.User.mail = await firebase.auth().currentUser.email;
                    context.state.User.passW = "123456";
                    await firebase.auth().currentUser.updatePassword(context.state.User.passW).catch((err) => {
                        alert(err);
                    });
                    await this.dispatch("pullDatabaseData");
                    await context.commit("updateUserData");
                    await context.commit("updateWeightData");
                    if (context.state.User.name == "" || context.state.User.name == null) {
                        router.push("/welcome");
                        this.commit("updateShowNavMenu", false);
                    }
                    else {
                        router.push("/");
                    }
                }
                else {
                    console.log("Login through App");
                    await Plugins.GoogleAuth.signIn().then(async (user) => {
                        context.state.userID = await user.id;
                        context.state.User.name = await user.givenName;
                        context.state.User.mail = await user.email;
                        context.state.User.passW = "123456";
                        context.state.gLogin = true;
                        const userData = { id: user.id, name: user.givenName, mail: user.email, passW: context.state.User.passW }
                        await this.dispatch("registerUser", userData).catch(async (err) => {
                            console.log(err);
                        });
                    }).catch(() => {
                        console.log("Google Login abgebrochen");
                        context.state.isLoading = false;
                    });
                }
            }
        },
        async loginUserWithFacebook(context) {
            if (context.state.isLogedIn == true) {
                alert("Sie sind noch eingeloggt");
            }
        },
        async logoutUser(context) {
            await firebase.auth().signOut();
            context.state.isLogedIn = false;
            // alert("Sie sind jetzt ausgeloggt");
        },
        async removeUserName(context) {
            context.state.User.name = "";
            await context.commit("updateUserData");
            router.push("/welcome");
        },
        async changeDBUserName(context) {
            await context.commit("updateUserData");
            if (context.state.isLogedIn == true) {
                context.state.isLoading = true;
                await firebase.auth().currentUser.updateProfile({ displayName: context.state.User.name });
                await context.dispatch("pushDatabaseData");
            }
        },
        async pushDatabaseData(context) {
            context.state.isLoading = true;
            await firebase.database().ref("Users/" + context.state.userID + "/").set({
                UserData: context.state.User,
                WeightData: context.state.WeightDataStored,
            });
            setTimeout(() => { context.state.isLoading = false; }, 500);

        },
        async pullDatabaseData(context) {
            if (context.state.isLogedIn == true) {
                context.state.isLoading = true;
                await firebase.database().ref("Users/" + context.state.userID + "/").once("value").then((obj) => {
                    if (obj.val() == null) {
                        this.dispatch("pushDatabaseData");
                    }
                    else {
                        const userData = obj.val();
                        context.state.User = userData.UserData;
                        context.state.WeightDataStored = userData.WeightData;
                    }
                },
                    (err) => {
                        alert(err);
                    }
                );
                setTimeout(() => { context.state.isLoading = false; }, 500);
            }
        },
        async addAndRemoveWeightData(context, WeightData) {
            await context.commit("updateWeightDataStored", WeightData);
            if (context.state.isLogedIn == true) {
                await this.dispatch("pushDatabaseData");
            }
        },
        async deleteUser(context) {
            await firebase.database().ref("Users/" + context.state.userID).remove().then(async () => {
                await firebase.auth().currentUser.delete().then(() => {
                    alert("Account gelöscht!");
                }).catch(function (err) {
                    console.log(err);
                    alert("Benutzer nicht gefunden!");
                });
            },
                (err) => {
                    console.log(err);
                }
            );
        },
        async pwReset(context, userMail) {
            await firebase.auth().sendPasswordResetEmail(userMail).then(() => {
                alert("Bitte überprüfen Sie den Posteingang der eingegebene E-Mail Adresse");
            }).catch((err) => {
                console.log(err);
                alert("Eingegebene E-Mail Adresse nicht gefunden!");
            });
        },
        async fetchData(context) {
            await this.dispatch("pullDatabaseData");
            context.state.pulledForRefresh = true;
            context.state.refresh = false;
        },
    },
});