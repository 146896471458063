<template>
  <div id="app">
    <div class="overlayAddWeight" v-show="showDeleteWarning">
      <div
        class="clickToExit"
        @click="showDeleteWarning = !showDeleteWarning"
      ></div>
      <div class="divNewWeight">
        <div class="inputErrorMessage">
          <p style="text-align: left">
            Hiermit löschen Sie ihren Account entgültig! Es findet keine weitere
            Synchronisation statt! Eine Widerherstellung zum späteren Zeitpunkt
            ist nicht möglich!<br /><br />
            Die Daten bleiben bis zum nächsten Login lokal gespeichert. Durch
            erneute Registrierung können Sie diese dann wieder synchronisieren.
          </p>
        </div>
        <button
          type="button"
          class="deleteNowButton"
          @click="
            showDeleteWarning = !showDeleteWarning;
            deleteUser();
          "
        >
          Entgültig löschen!
        </button>
      </div>
    </div>
    <header>
      <div class="status" @click="clickStatus">
        <!-- <img
          src="./assets/Icon_user.svg"
          alt="LogedIn"
          class="icon user"
          v-if="isLogedIn"
        />
        <img
          src="./assets/Icon_user_delete.svg"
          alt="NoLogedIn"
          class="icon userNo"
          v-if="!isLogedIn"
        /> -->
        <img
          src="./assets/Icon_cloud_light2.svg"
          alt="Sync"
          class="icon cloud"
          v-if="(isLogedIn || isRegistering) && !isLoading"
        />
        <img
          src="./assets/Icon_cloud download_light2.svg"
          alt="Sync"
          class="icon cloud"
          v-if="(isLogedIn || isRegistering) && isLoading"
        />
        <img
          src="./assets/Icon_no_cloud_light.svg"
          alt="NoSync"
          class="icon cloudNo"
          v-if="!isLogedIn && !isRegistering"
        />
      </div>
      <div
        class="navMenu"
        v-if="showNavMenu && !navShow"
        @click="navShow = !navShow"
      >
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
      </div>
      <div
        class="navOverlay"
        v-if="showNavMenu && navShow"
        @click="navShow = !navShow"
      >
        <div class="headerSlider">
          <nav class="nav">
            <ul v-show="isLogedIn" class="ulIn">
              <!-- <li><router-link to="/Welcome">Start</router-link></li> -->
              <li class="linkHome">
                <router-link to="/">Waagi</router-link>
              </li>
              <li class="linkLogout">
                <button class="navbarButton" @click="logOutUser">Logout</button>
              </li>
              <li class="linkName">
                <button class="navbarButton" @click="changeName">
                  Name ändern
                </button>
              </li>
              <li class="linkPlaystore">
                <button class="navbarButton" @click="openPlaystore">
                  Zum Playstore
                </button>
              </li>
              <li class="linkWeb">
                <button class="navbarButton" @click="openWeb">
                  Waagi im Web
                </button>
              </li>
              <li class="linkDelete">
                <button
                  class="navbarButton"
                  @click="showDeleteWarning = !showDeleteWarning"
                >
                  Account löschen
                </button>
              </li>
            </ul>
            <ul v-show="!isLogedIn" class="ulOut">
              <!-- <li><router-link to="/Welcome">Start</router-link></li> -->
              <li class="linkHome">
                <router-link to="/">Waagi</router-link>
              </li>
              <li class="linkLogin">
                <router-link to="/login">Login / Sync</router-link>
              </li>
              <li class="linkName">
                <button class="navbarButton" @click="changeName">
                  Name ändern
                </button>
              </li>
              <li class="linkPlaystore">
                <button class="navbarButton" @click="openPlaystore">
                  Zum Playstore
                </button>
              </li>
              <li class="linkWeb">
                <button class="navbarButton" @click="openWeb">
                  Waagi im Web
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      navShow: false,
      showDeleteWarning: false,
    };
  },
  computed: {
    ...mapGetters(["isLogedIn", "isLoading", "isRegistering", "showNavMenu"]),
  },
  mounted() {
    this.$store.commit("updateUserData");
    this.$store.dispatch("isUserLogedIn");
  },
  methods: {
    logOutUser() {
      this.$store.dispatch("logoutUser");
    },
    changeName() {
      this.$store.dispatch("removeUserName");
    },
    deleteUser() {
      this.$store.dispatch("deleteUser");
    },
    openPlaystore() {
      window.open(
        "https://play.google.com/store/apps/details?id=de.mweidenfeld.WeightApp",
        "_blank"
      );
    },
    openWeb() {
      window.open("https://waagi.de", "_blank");
    },
    clickStatus() {
      if (this.isLogedIn == true) {
        this.$store.commit("refresh");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/style.scss";
</style>