import Vue from 'vue';
import { store } from "./store";
import App from './App.vue';
import router from './router';
// import * as firebase from "firebase/app";
// import 'firebase/analytics';
// import 'firebase/auth';

import MainChart from './components/MainChart.vue';
import Scraper from './components/Scraper.vue';
import Autocomplete from '@trevoreyre/autocomplete-vue';

Vue.config.productionTip = true;

Vue.component('app-mainChart', MainChart)
Vue.component('app-scraper', Scraper)
Vue.use(Autocomplete)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
