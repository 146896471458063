<template>
  <div id="Login">
    <main id="main">
      <div class="allContent">
        <div class="box headerH1">
          <h1>Hi!</h1>
        </div>
        <div class="box mainlogin">
          <div class="mainloginConten boxContent">
            <p class="loginText">Wie ist dein Name:</p>
            <input
              type="text"
              id="inputName"
              class="outline input-bar loginInput inputName"
              placeholder="Vorname"
              v-model="enteredName"
              v-on:keyup.enter="enterUserName()"
            />
            <div
              class="searchErrorMessage welcomeError"
              v-html="'<p>' + searchErrorMessage + '</p>'"
              v-show="showErrorMessage"
            ></div>
            <button
              class="outline loginButton goLogin"
              @click="enterUserName()"
            >
              Los gehts!
            </button>
            <p class="loginText" v-show="!isLogedIn">Weitere Option:</p>
            <button
              to="/login"
              class="outline loginButton anmeldenButton"
              @click="login()"
              v-show="!isLogedIn"
            >
              Anmelden / Registrieren
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      enteredName: "",
      showErrorMessage: false,
      searchErrorMessage: "",
    };
  },
  created() {
    if (this.User.name == "" || this.User.name == null) {
      this.$store.commit("updateShowNavMenu", false);
    } else {
      this.$router.push("/");
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("inputName").focus();
    }, 100);
  },
  computed: {
    ...mapGetters(["User", "isLogedIn", "showNavMenu"]),
  },
  methods: {
    enterUserName() {
      if (this.enteredName == "") {
        this.searchErrorMessage = "Gib bitte einen Namen ein";
        this.showErrorMessage = true;
      } else {
        this.showErrorMessage = false;
        this.User.name = this.enteredName;
        this.$router.push("/");
        this.$store.dispatch("changeDBUserName");
        this.$store.commit("updateShowNavMenu", true);
      }
    },
    login() {
      this.$store.commit("updateShowNavMenu", true);
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
@import "src/style.scss";
</style>