<template>
  <div>
    <div class="mainScraperContent boxContent">
      <autocomplete
        id="autocomplete"
        ref="autocomplete"
        class="autocomplete"
        :search="search"
        placeholder="Nährwertsuche"
        aria-label="Nährwertsuche"
        :get-result-value="getResultValue"
        @submit="handleSubmit"
        autoSelect
      ></autocomplete>
      <div
        class="searchErrorMessage"
        v-html="'<p>' + searchErrorMessage + '</p>'"
        v-show="showErrorMessage"
      ></div>
      <div
        class="showFoodResult"
        ref="foodResult"
        v-show="showResult | !showResult"
      >
        <div class="showR1">{{ result.name }}</div>
        <p class="showR2">=</p>
        <div class="showR3">{{ result.kcal }}</div>
        <p class="showR4">kcal / 100g</p>
      </div>
      <!-- <div class="debugger">
      <p>
        <br />
        {{ debugVar1 }}<br />
        {{ debugVar2 }}<br />
        {{ debugVar3 }}<br />
        {{ debugVar4 }}<br />
        {{ debugVar5 }}<br />
        {{ debugVar6 }}<br />
        {{ debugVar7 }}
      </p>
    </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      // debugVar1: "",
      // debugVar2: "",
      // debugVar3: "",
      // debugVar4: "",
      // debugVar5: "",
      // debugVar6: "",
      // debugVar7: "",
      showErrorMessage: false,
      searchErrorMessage: "",
      showResult: false,
      result: { name: "Nahrungsmittel", kcal: "0" },
    };
  },
  created() {
    // this.debugVar1 = 0;
    // this.debugVar2 = 0;
    // this.debugVar3 = 0;
    // this.debugVar4 = 0;
    // this.debugVar5 = 0;
    // this.debugVar6 = 0;
    // this.debugVar7 = 0;
  },
  mounted() {},
  computed: {
    ...mapGetters(["foodDB"]),
  },
  methods: {
    search(input) {
      if (input.length < 1) {
        this.showErrorMessage = false;
        return [];
      } else if (input.length >= 1 && input.length < 20) {
        this.showErrorMessage = false;
        return this.foodDB.filter((food) => {
          return food.name.toLowerCase().startsWith(input.toLowerCase());
        });
      } else if (input.length >= 20) {
        this.searchErrorMessage = "Wähle bitte ein Element aus";
        this.showErrorMessage = true;
      }
    },
    getResultValue(food) {
      return food.name;
    },
    handleSubmit(result) {
      this.showResult = true;
      this.result = result;
      // const messageDisplay = this.$refs.foodResult;
      // messageDisplay.scrollTop = messageDisplay.scrollHeight;
      setTimeout(() => {
        const elScroll = this.$refs.foodResult;
        if (elScroll) {
          elScroll.scrollIntoView({ behavior: "smooth" });
        }
      }),
        100;
      this.$refs.autocomplete.setValue("");
    },
  },
};
</script>

<style lang="scss">
@import "src/style.scss";
</style>