<template>
  <div>
    <div class="mainWeightChartContent boxContent">
      <button
        class="outline addWeightButton"
        type="button"
        @click="
          showAddWeight = !showAddWeight;
          focusFirstInput();
        "
      >
        +
      </button>
      <div class="weight-now">
        <p>
          <span class="weightFont">{{
            WeightData[WeightData.length - 1].weight + " kg"
          }}</span>
          <br />
          Hallo {{ User.name }}<br />
          Es ist der {{ timestamp }}
        </p>
      </div>
      <div class="weight-diagram">
        <line-chart
          :styles="myLineChartStyle"
          label="Gewicht"
          ref="WeightChartUpdate"
          style="transition: 0s"
        />
      </div>
      <vue-slider
        class="slider slideNOfWeightData"
        width="80%"
        direction="rtl"
        v-model="nOfWeightData"
        @change="sliderUpdate()"
        :min="minSliderWeightData"
        :max="maxSliderWeightData"
        dotSize="18"
        adsorb
        tooltip="none"
        tooltipPlacement="bottom"
        v-if="WeightData.length > minSliderWeightData"
      >
      </vue-slider>
      <vue-slider
        class="slider slideNOfDate"
        width="80%"
        direction="rtl"
        v-model="nOfDatesBack"
        @change="sliderUpdate()"
        :max="maxSliderDates"
        :min="0"
        dotSize="18"
        adsorb
        tooltip="none"
        tooltipPlacement="bottom"
        silent
        v-if="maxSliderDates > 0"
      >
      </vue-slider>
      <button
        class="buttonShowRemovalList"
        @click="showRemovalList = !showRemovalList"
      >
        <p v-show="!showRemovalList">Eintrag entfernen?</p>
        <p v-show="showRemovalList">Zuklappen</p>
      </button>
      <div class="removalList" v-if="showRemovalList">
        <div class="WeightEntry" v-for="entry in WeightData" :key="entry.date">
          <button
            class="buttonRemoveWeightEntry"
            @click="removeWeightEntry(entry)"
          >
            Entfernen
          </button>
          <div class="WeightEntryContainer">
            <p class="WeightEntryDate">{{ entry.date }}</p>
            <p class="WeightEntryWeight">{{ entry.weight }}</p>
            <p class="WeightEntryKG">kg</p>
          </div>
        </div>
      </div>
      <!-- <div class="debugger">
        <p>
          <br />
          {{ debugVar1 }}<br />
          {{ debugVar2 }}<br />
          {{ debugVar3 }}<br />
          {{ debugVar4 }}<br />
          {{ debugVar5 }}<br />
          {{ debugVar6 }}<br />
          {{ debugVar7 }}<br />
          {{ debugVar8 }}<br />
          {{ debugVar9 }}<br />
          {{ debugVar10 }}
        </p>
      </div> -->
      <div class="overlayAddWeight" v-show="showAddWeight">
        <div class="clickToExit" @click="showAddWeight = !showAddWeight"></div>
        <div class="divNewWeight">
          <div
            class="inputErrorMessage"
            v-html="'<p>' + inputErrorMessage + '</p>'"
            v-show="showErrorMessage"
          ></div>
          <div class="inputWeightContainer">
            <input
              type="text"
              v-model="inputWeight"
              id="inputNewWeight"
              class="input-bar inputNewWeight"
              placeholder="Gewicht eingeben"
              @input="replaceCommas()"
              @keyup.enter="focusSecondInput()"
            />
            <div class="overlay-kg">kg</div>
          </div>
          <div class="inputDateContainer">
            <input
              type="text"
              v-model="inputDate"
              id="inputDate"
              class="input-bar inputDate"
              placeholder="TT.MM.JJJJ"
              @input="replaceCommas()"
              @keyup.enter="
                showAddWeight = !showAddWeight;
                addWeightNow();
              "
            />
            <button type="button" class="todayButton" @click="addTodayDate()">
              Heute
            </button>
          </div>
          <button
            type="button"
            class="addNowButton"
            @click="
              showAddWeight = !showAddWeight;
              addWeightNow();
            "
          >
            Hinzufügen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "./LineChart.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";

export default {
  components: {
    LineChart,
    VueSlider,
  },
  data() {
    return {
      debugVar1: "",
      debugVar2: "",
      debugVar3: "",
      debugVar4: "",
      debugVar5: "",
      debugVar6: "",
      debugVar7: "",
      debugVar8: "",
      debugVar9: "",
      debugVar10: "",
      WeightData: [],
      chartOptions: {},
      WeightDataChart: [],
      lastWeight: "0",
      showAddWeight: false,
      timestamp: "...",
      inputWeight: "",
      inputIsValid: false,
      showErrorMessage: false,
      inputErrorMessage: "",
      inputDate: "",
      nOfWeightData: 0,
      minSliderWeightData: 3,
      maxSliderWeightData: 0,
      nOfDatesBack: 0,
      maxSliderDates: 0,
      showRemovalList: false,
    };
  },
  created() {
    this.debugVar1 = 0;
    this.debugVar2 = 0;
    this.debugVar3 = 0;
    this.debugVar4 = 0;
    this.debugVar5 = 0;
    this.debugVar6 = 0;
    this.debugVar7 = 0;
    this.debugVar8 = 0;
    this.debugVar9 = 0;
    this.debugVar10 = 0;

    this.WeightData = this.WeightDataStored;
    this.chartOptions = this.chartOptionsStored;

    this.nOfWeightData = this.WeightData.length;

    this.maxSliderWeightData = this.WeightData.length;
    this.maxSliderDates = this.WeightData.length - this.nOfWeightData;

    setTimeout(this.getToday, 500);
  },
  mounted() {
    this.sliderUpdate();
    setTimeout(() => {
      this.checkForLogin();
    }, 1000);
    // this.refreshNow();
  },
  computed: {
    ...mapGetters([
      "User",
      "WeightDataStored",
      "WeightDataChartStored",
      "chartOptionsStored",
      "isLogedIn",
      "refresh",
      "pulledForRefresh",
      "refreshInterval",
    ]),
    myLineChartStyle: function () {
      return {
        height: "100%",
        width: "100%",
        // background: "rgba(0,0,0,1)",
        position: "relative",
      };
    },
  },
  watch: {
    refresh(newBoolean, oldBoolean) {
      if (newBoolean == true) {
        this.refreshNow();
      }
      if (newBoolean == true && oldBoolean == true) {
        console.log("Error42");
      }
    },
    isLogedIn(newBoolean, oldBoolean) {
      setTimeout(() => {
        this.checkForLogin();
        if (newBoolean == true && oldBoolean == true) {
          console.log("Error42");
        }
      }, 1000);
    },
  },
  methods: {
    async refreshNow() {
      if (this.isLogedIn == true) {
        await this.$store.dispatch("fetchData");
        if (this.pulledForRefresh == true) {
          await this.sliderUpdate();
          this.$store.commit("resetPulledForRefresh");
        }
      } else {
        console.log("Refresh Funktion ausgeschaltet!");
      }
    },
    async runInterval() {
      setTimeout(() => {
        // console.clear();
        this.refreshNow();
        if (this.refreshInterval == true) {
          this.runInterval();
        }
      }, 20000);
    },
    checkForLogin() {
      if (this.isLogedIn == true) {
        this.$store.commit("refreshInterval", true);
        console.log("Eingeloggt");
        this.refreshNow();
        this.runInterval();
      } else if (this.isLogedIn == false) {
        console.log("Ausgeloggt");
        this.$store.commit("refreshInterval", false);
      }
    },
    updateWeightChart() {
      this.WeightData.sort(this.arrCompare);
      this.WeightData = this.removeDoubleEntries(this.WeightData);

      this.WeightDataChart.sort(this.arrCompare);

      this.setYValues();

      this.$store.commit("updateWeightDataStored", this.WeightData);
      this.$store.commit("updateWeightDataChartStored", this.WeightDataChart);

      this.$refs.WeightChartUpdate.drawChart();
    },
    sliderUpdate() {
      this.WeightDataChart = [];

      if (this.isLogedIn == true) {
        this.WeightData = this.WeightDataStored;
      }

      if (this.maxSliderWeightData > this.WeightData.length) {
        this.nOfWeightData--;
      }
      this.maxSliderWeightData = this.WeightData.length;
      this.maxSliderDates = this.WeightData.length - this.nOfWeightData;
      if (this.maxSliderDates <= this.nOfDatesBack) {
        this.nOfDatesBack = this.maxSliderDates;
      }

      for (var i = 0; i < this.nOfWeightData; i++) {
        this.WeightDataChart.push(
          this.WeightData[this.WeightData.length - i - this.nOfDatesBack - 1]
        );
      }

      this.updateWeightChart();
    },
    addWeightNow: function () {
      const pushIntoWeightData = {
        weight: this.inputWeight,
        date: this.inputDate,
      };

      this.weightDataChart = [];

      this.inputValidation(pushIntoWeightData);

      if (this.inputIsValid == true) {
        const WeightDataLength = this.WeightData.length;

        if (this.WeightData.length == 1 && this.WeightData[0].weight == 0) {
          this.WeightData = [];
        }

        this.WeightData.push(pushIntoWeightData);
        this.WeightData.sort(this.arrCompare);
        this.WeightData = this.removeDoubleEntries(this.WeightData);

        if (WeightDataLength != this.WeightData.length) {
          this.nOfWeightData++;
        }

        this.$store.dispatch("addAndRemoveWeightData", this.WeightData);

        this.inputIsValid = false;

        this.inputWeight = "";
        this.inputDate = "";

        this.sliderUpdate();
      }
    },
    inputValidation: function (input) {
      const dateNum = this.convertStringToDate(input.date);
      let weightValid = false;
      let dateValid = false;

      if (input.weight > 0 && input.weight < 500) {
        weightValid = true;
      }

      if (dateNum > 9999999 && dateNum < 100000000) {
        dateValid = true;
      }

      if (weightValid == false) {
        this.inputIsValid = false;
        // this.inputWeight = "";
        this.inputErrorMessage = "Falsches Gewicht eingegeben";
        this.showAddWeight = true;
        this.showErrorMessage = true;

        if (dateValid == false) {
          // this.inputDate = "";
          this.inputErrorMessage =
            "Falsches Gewicht und<br>falsches Datum eingegeben";
        }

        return;
      } else if (weightValid == true && dateValid == false) {
        this.inputIsValid = false;
        // this.inputDate = "";
        this.inputErrorMessage = "Falsches Datum eingegeben";
        this.showAddWeight = true;
        this.showErrorMessage = true;

        return;
      } else if (weightValid == true && dateValid == true) {
        this.inputIsValid = true;
        this.inputErrorMessage = "";
        this.showErrorMessage = false;

        return;
      }
    },
    convertStringToDate: function (strDate) {
      const calcYear = Number(strDate.slice(6));
      const calcMonth = Number(strDate.slice(3, 5));
      let monthAddZero = "";
      const calcDays = Number(strDate.slice(0, 2));
      let daysAddZero = "";

      if (calcMonth < 10) {
        monthAddZero = "0";
      }

      if (calcDays < 10) {
        daysAddZero = "0";
      }

      const newIntDate = Number(
        calcYear.toString() +
          monthAddZero +
          calcMonth.toString() +
          daysAddZero +
          calcDays.toString()
      );

      monthAddZero = "";
      daysAddZero = "";

      return newIntDate;
    },
    arrCompare: function (a, b) {
      const dateA = this.convertStringToDate(a.date);
      const dateB = this.convertStringToDate(b.date);
      let comparison = 0;

      if (dateA > dateB) {
        comparison = 1;
      } else if (dateA < dateB) {
        comparison = -1;
      }

      return comparison;
    },
    removeDoubleEntries: function (array) {
      let arrLast = 0;
      let foundDouble = false;

      for (var i = 0; i < array.length; i++) {
        if (arrLast == array[i].date) {
          array.splice(i - 1, 1);
          i = i - 1;
          foundDouble = true;
        }
        arrLast = array[i].date;
        if (i == array.length - 1 && foundDouble == true) {
          arrLast = 0;
          foundDouble = false;
          i = -1;
        } else if (i == array.length - 1 && foundDouble == false) {
          arrLast = 0;
          return array;
        }
      }
    },
    setYValues: function () {
      let arrTemp = [];
      let useArray = [];

      // useArray = this.WeightDataChart;
      useArray = this.WeightData;

      for (var i = 0; i < useArray.length; i++) {
        arrTemp.push(useArray[i].weight);
      }

      const minYScale = Math.min(...arrTemp);
      const maxYScale = Math.max(...arrTemp);

      const difMinMax = (maxYScale - minYScale) / 4;

      let minY = minYScale - difMinMax;
      const maxY = maxYScale + difMinMax;

      if (minY <= 0) {
        minY = 0;
      }

      this.chartOptions.scales = {
        yAxes: [
          {
            // display: false,
            scaleLabel: {
              // display: true,
              labelString: "Gewicht",
            },
            ticks: {
              min: minY,
              max: maxY,
              fontColor: "rgb(180, 180, 180)",
              // stepSize: 1,
            },
          },
        ],
        xAxes: [
          {
            // display: false,
            scaleLabel: {
              // display: true,
              labelString: "Datum",
              fontColor: "rgb(180, 180, 180)",
            },
            ticks: {
              fontColor: "rgb(180, 180, 180)",
              // stepSize: 10,
            },
          },
        ],
      };
    },
    getToday: function () {
      const today = new Date();
      const dateDay = today.getDate();
      let dateDayAddZero = "";
      const dateMonth = today.getMonth() + 1;
      let dateMonthAddZero = "";
      const dateYear = today.getFullYear();
      // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      if (dateDay < 10) {
        dateDayAddZero = "0";
      }
      if (dateMonth < 10) {
        dateMonthAddZero = "0";
      }

      const date =
        dateDayAddZero +
        dateDay +
        "." +
        dateMonthAddZero +
        dateMonth +
        "." +
        dateYear;
      const dateTime = date;
      this.timestamp = dateTime;

      dateDayAddZero = "";
      dateMonthAddZero = "";
    },
    addTodayDate: function () {
      this.inputDate = this.timestamp;
    },
    removeWeightEntry: function (entry) {
      const indexOfWeightData = this.WeightData.indexOf(entry, 0);
      if (this.WeightData.length > 1) {
        this.WeightData.splice(indexOfWeightData, 1);
        this.$store.dispatch("addAndRemoveWeightData", this.WeightData);
        this.sliderUpdate();
      }
    },
    focusFirstInput() {
      setTimeout(() => {
        document.getElementById("inputNewWeight").focus();
        document.getElementById("inputNewWeight").select();
        if (this.inputDate == "") {
          this.addTodayDate();
        }
      }, 100);
    },
    focusSecondInput() {
      document.getElementById("inputDate").focus();
    },
    replaceCommas() {
      this.inputWeight = this.inputWeight.replace(/,/g, ".");
      this.inputDate = this.inputDate.replace(/,/g, ".");
    },
  },
};
</script>

<style lang="scss">
@import "src/style.scss";
</style>