import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyB1oyfBkexIj-P9piiUghGekAdMrcWJtII",
    authDomain: "weight-tracker-mwdesign.firebaseapp.com",
    databaseURL: "https://weight-tracker-mwdesign.firebaseio.com",
    projectId: "weight-tracker-mwdesign",
    storageBucket: "weight-tracker-mwdesign.appspot.com",
    messagingSenderId: "1056661719654",
    appId: "1:1056661719654:web:39dba5e662ccbd36e1bba7",
    measurementId: "G-1PYHBRV989"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;