// import { Plugins } from '@capacitor/core';
// const { App } = Plugins;

import Vue from 'vue';
import VueRouter from 'vue-router';

import Welcome from '@/views/Welcome.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/Welcome',
    },
    {
        path: '/',
        component: Home,
    },
    {
        path: '/Welcome',
        component: Welcome,
    },
    {
        path: '/Login',
        component: Login,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

// App.addListener('appUrlOpen', function (data) {
//     // Example url: https://beerswift.app/tabs/tabs2
//     // slug = /tabs/tabs2
//     const slug = data.url.split('.app').pop();

//     // We only push to the route if there is a slug present
//     if (slug) {
//         router.push({
//             path: slug,
//         });
//     }
// });

export default router;