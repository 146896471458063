<template>
  <div id="Login">
    <main id="main">
      <div class="allContent">
        <div class="box headerH1">
          <h1>Sync</h1>
        </div>
        <div class="box mainlogin">
          <div class="mainloginContent boxContent">
            <p class="loginText">Mit bestehendem<br />Sync-Konto anmelden:</p>
            <input
              type="email"
              class="outline login-input-bar loginInput loginMail"
              placeholder="E-Mail"
              v-model="userLoginMailInput"
              v-on:keyup.enter="focusSecondMailInput"
            />
            <input
              type="password"
              class="outline login-input-bar loginInput loginPW"
              placeholder="Passwort"
              v-model="userLoginPWInput"
              v-on:keyup.enter="loginUserWithMail"
            />
            <button
              class="outline loginButton mailLogin"
              @click="loginUserWithMail"
            >
              Login mit Zugangsdaten
            </button>
            <button class="outline loginButton pwReset" @click="pwReset">
              Passwort vergessen?
            </button>
            <p class="loginText">Oder einfach:</p>
            <button
              class="outline loginButton googleButton"
              @click="loginUserWithGoogle"
            >
              <p>Sync mit</p>
              <img
                src="../assets/google.png"
                alt="Google Login"
                class="googleLogin"
              />
            </button>
            <!-- <button
                class="outline loginButton facebookButton"
                @click="loginUserWithFacebook"
              >
                <p>Login mit</p>
                <img
                  src="../assets/facebook.png"
                  alt="Facebook Login"
                  class="facebookLogin"
                />
              </button> -->
            <p class="loginText">Neu hier?<br />Sync-Konto erstellen:</p>
            <input
              type="text"
              class="outline login-input-bar loginInput inputName"
              placeholder="Vorname"
              v-model="userRegisterNameInput"
              v-on:keyup.enter="focusSecondRegisterInput"
            />
            <input
              type="email"
              class="outline login-input-bar loginInput loginMail"
              placeholder="E-Mail"
              v-model="userRegisterMailInput"
              v-on:keyup.enter="focusThirdRegisterInput"
            />
            <input
              type="password"
              class="outline login-input-bar loginInput loginPW"
              placeholder="Passwort"
              v-model="userRegisterPWInput"
              v-on:keyup.enter="registerUserWithMail"
            />
            <button
              class="outline loginButton registerButton"
              @click="registerUserWithMail"
            >
              Jetzt registrieren
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      userLoginMailInput: "",
      userLoginPWInput: "",
      userRegisterNameInput: "",
      userRegisterMailInput: "",
      userRegisterPWInput: "",
    };
  },
  methods: {
    loginUserWithMail() {
      const userLoginData = {
        mail: this.userLoginMailInput,
        passW: this.userLoginPWInput,
      };
      this.$store.dispatch("loginUser", userLoginData);
      // this.userLoginMailInput = "";
      // this.userLoginPWInput = "";
    },
    pwReset() {
      if (this.userLoginMailInput == "" || this.userLoginMailInput == null) {
        alert("Bitte geben Sie erst Ihre Mail Adresse in das Feld E-Mail ein!");
      } else {
        this.$store.dispatch("pwReset", this.userLoginMailInput);
        // this.userLoginMailInput = "";
        // this.userLoginPWInput = "";
      }
    },
    loginUserWithGoogle() {
      this.$store.dispatch("loginUserWithGoogle");
    },
    loginUserWithFacebook() {
      this.$store.dispatch("loginUserWithFacebook");
    },
    registerUserWithMail() {
      const userRegistrationData = {
        name: this.userRegisterNameInput,
        mail: this.userRegisterMailInput,
        passW: this.userRegisterPWInput,
      };
      this.$store.dispatch("registerUser", userRegistrationData);
      // this.userRegisterNameInput = "";
      // this.userRegisterMailInput = "";
      // this.userRegisterPWInput = "";
    },
    focusSecondMailInput() {
      document.getElementsByClassName("loginPW")[0].focus();
    },
    focusSecondRegisterInput() {
      document.getElementsByClassName("loginMail")[1].focus();
    },
    focusThirdRegisterInput() {
      document.getElementsByClassName("loginPW")[1].focus();
    },
  },
};
</script>

<style lang="scss">
@import "src/style.scss";
</style>